import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import store, { persistor } from './redux/store/store';
import { Provider } from 'react-redux';
import axiosInterceptors from "./services/config/axiosInterceptors";
import { PersistGate } from 'redux-persist/integration/react';

import Alert from 'components/Alert/Alert';
import Loader from 'components/Loader/Loader';
import Toast from "components/Toast/Toast";
import InlineLoader from "components/Loader/InlineLoader";

// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useSelector, useDispatch } from "react-redux";

//Services
import userService from "./services/userService";
import { actionsAuth } from "./redux";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import WebSocketProvider from "helpers/websocket";
import WebSocketConnection from "helpers/websocketConnection";

const AuthLayout = lazy(() => import("layouts/Auth.js"));
const HomeLayout = lazy(() => import("layouts/Home.js"));

/*
const firebaseConfig = {
  apiKey: "AIzaSyAofJoOnItTFrtOWrLjX6UntTe_zc1mBZg",
  authDomain: "depot-express-6b508.firebaseapp.com",
  projectId: "depot-express-6b508",
  storageBucket: "depot-express-6b508.appspot.com",
  messagingSenderId: "161607025736",
  appId: "1:161607025736:web:be0ecec2aef5eace99447d",
  measurementId: "G-5PN0DN20C8"
};

// Initialize Firebase
const fbapp = initializeApp(firebaseConfig);
var swRegistration = [];

// Initialize Firebase Cloud Messaging and get a reference to the service
// const analytics = getAnalytics(app);
const messaging = getMessaging(fbapp);

function requestToken(messaging) {
  getToken(messaging, { vapidKey: process.env.REACT_APP_FBKEYPAIR }).then((currentToken) => {
    if (currentToken) {
      var ua = navigator.userAgent;
      var M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      localStorage.setItem("depotFCMToken", JSON.stringify(
        {
          "DeviceId": navigator.vendor,
          "DeviceModel": M ? M[1] : "WebBrowser",
          "DeviceToken": currentToken
        }
      ));
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });
}

const isNotificationSupported = () =>
'Notification' in window &&
'serviceWorker' in navigator &&
'PushManager' in window

function requestPermission(messaging) {
  if (!isNotificationSupported()) return false;

  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      requestToken(messaging)


      if ('serviceWorker' in navigator && 'PushManager' in window) {
        console.log('Service Worker and Push is supported');
      
        navigator.serviceWorker.register('/sw.js')
        .then(function(swReg) {
          console.log('Service Worker is registered', swReg);
          swRegistration = swReg;
          console.log(swReg)
        })
        .catch(function(error) {
          console.error('Service Worker Error', error);
        });
      } else {
        console.warn('Push messaging is not supported');
      }


    } else {
      console.log('Unable to get permission to notify.');
    }
  });
}
*/

axiosInterceptors.interceptor(store);

const hist = createBrowserHistory();

export default function InitialPage() {
  const dispatch = useDispatch();
  
  /*
  requestPermission(messaging);

  if (!isNotificationSupported()) {
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload); 
      if (payload) {
        var data = payload.data

        
        if (payload.notification.title == 'test') {
          console.log("TestLocal:", swRegistration)
          try {
            swRegistration.showNotification('Title Test', {
              "body": "Test",
              "actions": [
                { "action": "yes", "title": "Yes" },
                { "action": "no", "title": "No" }
              ]
            });
          } catch(e){
            console.log("error", e)
          }
        }

        if (data['action']) {
          if (data['action'] == "logout") {
            (async()=>{
              await userService.logout(dispatch, {
                Callback: ()=>{
                  dispatch(actionsAuth.authLogout());
                }
              });
            })(dispatch); 
          } else if (data['action'] == "load_wallet") {
            // TODO: reload only the wallet
            window.location.reload();
          }
        }
      }
      // Update the UI to include the received message.
      // appendMessage(payload);
    });
  }
  */

  return (
    <>
      <Alert />
      <Loader />
      <Toast />
      <Router history={hist}>
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <Route path={"/client"} component={HomeLayout} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      </Router>
    </>
  )
}

 ReactDOM.render(<Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <WebSocketProvider>
      <Suspense fallback={<InlineLoader />}>
        <WebSocketConnection>
          <InitialPage/>
        </WebSocketConnection>
      </Suspense>
    </WebSocketProvider>
  </PersistGate>
</Provider>, document.getElementById("root"));
