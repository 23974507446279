import React, { useEffect, useContext } from "react";
import { ReadyState } from "react-use-websocket";
import { WebsocketContext } from "./websocket";

export default function WebSocketConnection({ children }) {
  const [ready, val, sendMessage] = useContext(WebsocketContext);

  useEffect(() => {
    if (ready == ReadyState.OPEN) {
        console.log("READY")
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  useEffect(()=>{
    console.log(val);
  }, [val]);

  return <>{children}</>;
}
