import { WebsocketUrl } from "constants/ApiSettings";
import React, { createContext, useEffect, useState, useRef } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { ReadyState } from "react-use-websocket";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";

const isAuthenticated = localStorage.getItem("accessToken");
export const WebsocketContext = createContext(false, null, () => {});

const WebSocketProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [val, setVal] = useState(null);
  const state = useSelector(state => state.auth);

  const ws = useRef(null);

  // Websocket connection
  const getSocketUrl = useCallback(() => {
    var userId = state?.User?.Id ? state?.User?.Id : 'admin';
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(`${WebsocketUrl}?t=${userId}&d=web`);
      }, 2000);
    });
  }, [state]);


  const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } = useWebSocket(
    getSocketUrl,
    { 
      reconnectAttempts: 10,
      shouldReconnect: (closeEvent) => true,
      reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 10000, 60000),
      share: true,
      onOpen: () => setIsReady(true),
      onClose: () => setIsReady(false),
      onMessage: (msg) => setVal(JSON.parse(msg?.data)),
     }
  );

  useEffect(() => {
    // Open a basic annonymous connection
    if (readyState == ReadyState.OPEN) {
      ws.current = sendJsonMessage;
    }

    return () => {
      console.log('Closed')
      // socket.close();
    };
  }, [readyState, getWebSocket]);

  const ret = [isReady, val, sendJsonMessage.bind(ws.current)];

  return (
    <WebsocketContext.Provider value={ret}>
      {children}
    </WebsocketContext.Provider>
  );
};

export default WebSocketProvider;
