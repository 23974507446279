export const Ok = "OK";
export const Error = "ERROR";
export const NoEmail = "NO_EMAIL";
export const NoEmailConfirm = "NO_EMAIL_CONFIRM";
export const UsernameNotValid = "USERNAME_NOT_VALID";
export const PasswordRequired = "PASSWORD_REQUIRED";
export const UsernameTaken = "USERNAME_TAKEN";
export const EmailTaken = "EMAIL_TAKEN";
export const UserLocked = "USER_LOCKED";
export const TokenNotValid = "TOKEN_NOT_VALID";
export const LoginInvalid = "LOGIN_INVALID";
export const TokenRevoked = "TOKEN_REVOKED";
export const NoUsernameOrEmail = "NO_USERNAME_OR_EMAIL";