import React, {useEffect, useState} from "react";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import loaderIcon from '../../assets/img/logo_icon@2x.png';

import Lottie from 'lottie-react';
import animationData from '../../assets/lottie/spinner_1.json';

//Redux
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 200,
    backgroundColor: '#ffffff',
    color: '#fff',
  },
}));




export default function Loader() {
  const classes = useStyles();
  const state = useSelector(state => state.loader.Show);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={state}>
        <Lottie animationData={animationData} loop={true} autoplay={true} style={{opacity: 0.8}} />
        <img src={loaderIcon} style={{ width: '193px', height: '193px', position: 'absolute', zIndex:9 }} />
      </Backdrop>
    </div>
  );
}
